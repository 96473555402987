@import 'assets/styles/variables.scss';

html {
  font-family: $font-family;
  font-size: $font-size-base;
  height: 100%;
  line-height: $line-height-base;
  letter-spacing: 0.5px;
}

body {
  height: 100%;
  margin: 0;
}
