@import '../../assets/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: calc(100vh - $nav-height);
  align-items: center;
  justify-content: space-between;
  background-color: #eff1f5;
}
