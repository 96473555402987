@import '../../../assets/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  padding: 16px 64px 0;
  width: 800px;
  // // had to add this to get the email to stay in place when expanding the chain, but seems hacky
  // number is derived from navbar and bottom score container
  max-height: calc(100vh - 292px);
  overflow-y: scroll;
}

.Subject {
  font-weight: $light-bold;
  margin-bottom: 1rem;
}

.ClassificationText {
  text-align: center;
  margin-bottom: 16px;
}

.BodyContainer {
  color: $color-dark-gray;
  line-height: 140%;
  padding: 12px;
}

.TaskBodyContainer {
  composes: BodyContainer;
  border: 1px solid $color-blue;
}

.Toggle {
  margin-bottom: 2rem;
}
