@import '../../assets/styles/variables.scss';

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.Loading {
  top: 50%;
  left: 50%;
  margin: 0;
  text-align: center;
  z-index: 1000;
  position: absolute;
  width: 42px;
  height: 42px;
  margin-top: 6em;
  transform: translateX(-50%) translateY(-50%);
  &:before {
    height: 42px;
    margin: -1.5em 0 0 -1.5em;
    width: 42px;
    position: absolute;
    content: '';
    top: 0%;
    left: 50%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
  }
  &:after {
    height: 42px;
    margin: -1.5em 0 0 -1.5em;
    width: 42px;
    position: absolute;
    content: '';
    top: 0%;
    left: 50%;
    animation: loader 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0px 0px 0px 1px transparent;
    border-color: #47a7f3 transparent transparent;
  }
}
.Overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: $z-index-layer-three;
  background-color: rgba(255, 255, 255, 0.7);
}
