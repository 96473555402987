.Container {
  padding: 12px 0;
  width: 100%;
  max-height: 220px;
  display: flex;
  justify-content: center;
}

.Content {
  max-width: 800px;
  display: flex;
  flex-direction: column;
}

.Options {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.Card {
  margin-top: 0;
  padding: 12px;
}

.TextAreaContainer {
  margin-top: 8px;
}

.TextArea {
  resize: none;
}

.ActionButtons {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}
