@import '../../assets/styles/variables.scss';

.Container {
  width: 100%;
  background: white;
  height: 100%;
  padding-top: 63px;
}

.Spinner {
  background: rgba(255, 255, 255, 0.4);
  z-index: $z-index-layer-three;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100%;
}

.FormContainer {
  width: 100%;
  max-width: 400px;
  margin: auto;
  position: relative;
}

.Header {
  font-size: 48px;
  line-height: 120%;
  font-weight: 700;
  color: #002240;
  text-align: center;
  margin-bottom: 33px;
}

.ResetHeader {
  composes: Header;
  margin-top: 90px;
  margin-bottom: 20px;
}

.Subheader {
  text-align: center;
  margin: 0 auto 33px auto;
  max-width: 350px;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.Info {
  border-radius: 4px;
  margin-top: 16px;
  padding: 8px 12px 8px 14px;
  font-size: 14px;
  line-height: 150%;
  background-color: #e1eaf2;
}

.Links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  a {
    color: #0688fa;
    font-size: 16px;
    line-height: 180%;
    text-decoration: underline;
  }
}

.LinksPasswordLess {
  margin-top: 32px;
}
.LoginError {
  display: flex;
  line-height: 150%;
  font-size: 14px;
  color: $label-red;
  background: #efd9d9;
  padding: 10px 16px;
  border-radius: 5px;
  line-height: 125%;
  margin-top: 16px;
}
.LoginErrorIcon {
  margin-right: 12px;
}
.Button {
  width: 100%;
}

.Nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  position: fixed;
  top: 0;
  font-size: 18px;
}
.NavButton {
  padding: 12px 16px;
  font-size: 18px;
  line-height: 19px;
  color: #0688fa;
  border: 1px solid #0688fa;
  margin-left: 25px;
  background: transparent;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    background-color: #192480;
    border-color: #192480;
  }
}
