@import '../../../assets/styles/variables.scss';

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.EmailContainer {
  background: $color-white;
  border-radius: 6px;
  border: 1px solid $border-color-light;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
}

.HeaderContainer {
  width: 100%;
  border-bottom: 1px solid $border-color-light;
  display: flex;
  padding: 12px;
  box-sizing: border-box;
}

.HeaderRight {
  display: flex;
}

.Details {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.SenderName {
  font-size: 12px;
  font-weight: $light-bold;
}

.Receivers,
.Ccs {
  color: $color-grey;
  font-size: 12px;
}

.Body {
  max-width: 100%;

  a {
    color: #0083e3;
  }

  p {
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }

  blockquote {
    border-left: 1px solid #e4e7ec;
    margin: 10px 0 0 3px;
    padding-left: 1.25em;
  }

  .Blockquote {
    display: none;
    opacity: 0;
  }
}

.BodyOpen {
  composes: Body;

  .Blockquote {
    animation: slideDown 0.5s 1 forwards;
    display: block;
  }
}

.Toggle {
  margin: 10px 0 0;
}
