@import '../../assets/styles/variables.scss';

.Container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 180%;
  margin-bottom: 24px;
}
.Label {
  font-size: 18px;
  line-height: 180%;
}
.Error {
  .Input {
    color: $label-red;
    background-color: rgba($label-red, 0.2);
    border: $label-red;
  }
}
.Input {
  background: #fbfcfd;
  border: 1px solid #e1eaf2;
  border-radius: 5px;
  box-shadow: inset 2px 2px 8px rgb(0 0 0 / 8%);
  font-size: 18px;
  font-weight: 400;
  line-height: 180%;
  margin-top: 9px;
  padding: 6px 21px;
}
.ErrorMessage {
  color: $label-red;
  font-size: 14px;
  line-height: 125%;
  margin-top: 5px;
}
