@import '../../assets/styles/variables.scss';

.Container {
  display: flex;
  width: 100vw;
  height: calc(100vh - $nav-height);
}
.Left {
  width: 50%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  color: #1a1919;
  padding: 2rem 4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Description {
    width: 400px;
    margin-left: auto;
    margin-right: 48px;
  }
  .Title {
    font-weight: 900;
    font-size: 2rem;
    line-height: 120%;
    color: #252f35;
    margin-top: 0;
  }
  .Subtitle {
    margin-top: 22px;
    font-size: 1rem;
    line-height: 160%;
    color: #7f9099;
  }
  .Button {
    margin-top: 20px;
    width: 100%;
  }
}
.Right {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  background-color: #eff1f5;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    padding-left: 64px;
    padding-right: 64px;
    width: 574px;
  }
}
