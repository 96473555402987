.Card {
  cursor: pointer;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
  margin-top: 20px;
  padding: 26px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;

  &.Active {
    border: 2px solid #0083e3;
  }
  p {
    margin: 0;
  }
}
.Info {
  margin-left: 32px;
}
.Name {
  font-weight: 700;
  font-size: 1rem;
  line-height: 120%;
  color: #1a1919;
}
.Desc {
  font-weight: 400;
  font-size: 0.9rem;
  color: #7f9099;
  padding-right: 8px;
}

.Select {
  margin-left: auto;
  content: '';
  background-image: url('../../assets/images/inactive-check.png');
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  min-width: 24px;

  &.Active {
    content: '';
    background-image: url('../../assets/images/active-check.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
  }
}

.Icon {
  width: 46px;
  height: 46px;
  min-width: 46px;
}
