@import '../../assets/styles/variables.scss';

.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-height: 32px;
  padding: 9px 12px;
  font-size: 18px;
  font-weight: $light-bold;
  line-height: 18px;
  outline: none;
  border: none;
  text-decoration: none;
  border-radius: 4px;
  background-color: #0688fa;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: $color-active-dark;
  }
  &:active {
    background-color: darken(#0688fa, 15%);
  }

  &:hover,
  &:active {
    transition: background-color 0.1s ease;
  }

  &:disabled.Button {
    background-color: #e8e9ea;
    color: #a8acaf;
    cursor: not-allowed;
    svg {
      opacity: 0.45;
    }
    &:hover,
    &:active {
      background-color: #e8e9ea;
    }
  }
}
