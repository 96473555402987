@import '../../assets/styles/variables.scss';

.ToggleButton {
  position: relative;
  color: $color-default;

  &__label {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: $light-bold;
    color: $color-default;
    line-height: 20px;

    &--required {
      &:after {
        content: '*';
        color: $color-bright-red;
        margin-left: 3px;
      }
    }
  }
  &__infoIcon {
    transform: translateY(2px);
  }
  &__inputWrapper {
    display: flex;
    align-items: center;
    label {
      width: 42px;
      height: 21px;
      input {
        display: none;
        &:disabled ~ .ToggleButton__toggle {
          cursor: default;
          opacity: 0.6;
        }
        &:checked ~ .ToggleButton__toggle {
          &::before {
            background-color: $color-blue;
          }
          &::after {
            left: 23.8px;
          }
        }
      }
      &:hover {
        .ToggleButton__toggle::before {
          background-color: #c2cbd5;
        }
      }
      .ToggleButton__toggle {
        width: 42px;
        height: 21px;
        position: relative;
        cursor: pointer;
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          transition: 0.1s ease;
        }
        &::before {
          background-color: #dae0e5;
          top: 0;
          left: 0;
          width: 42px;
          height: 21px;
          border-radius: 500rem;
        }
        &::after {
          top: 2.5px;
          left: 2.5px;
          width: 16px;
          height: 16px;
          z-index: 1;
          box-shadow: 0px 1px 2px 0 rgb(34 36 38 / 15%), 0px 0px 0px 1px rgb(34 36 38 / 15%) inset;
          background: #ffffff linear-gradient(transparent, rgba(0, 0, 0, 0.05));
          border-radius: 50%;
        }
      }
    }
  }
  &__text {
    margin: 0 0 0 10px;
  }

  &__icon {
    position: absolute;
    display: flex;
    align-items: center;
    top: 6px;
    z-index: $z-index-layer-one;
  }
}
