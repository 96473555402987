@import '../../assets/styles/variables.scss';

.Spinner {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  pointer-events: none;
  cursor: default;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 3px solid;
    border-color: $color-blue rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
    animation: spinner 0.6s linear;
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 0px 1px transparent;
    z-index: 101;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &--inline {
    position: fixed;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 101;
      background-color: rgba(255, 255, 255, 0.8);
    }

    &:after {
      top: 50%;
      left: 50%;
      margin: -21px 0em 0em -21px;
      width: 42px;
      height: 42px;
    }
  }
}

.Spinner--small {
  width: 10px;
  height: 10px;

  &:after {
    width: 10px;
    height: 10px;
  }
}

.Spinner--medium {
  width: 24px;
  height: 24px;

  &:after {
    width: 24px;
    height: 24px;
  }
}

.Spinner--large {
  width: 42px;
  height: 42px;

  &:after {
    width: 42px;
    height: 42px;
  }
}
