@import '../../assets/styles/variables.scss';

.TextArea {
  font-family: $font-family;
  border: 1px solid $border-color-card;
  border-radius: 6px;
  color: $color-default;
  display: flex;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 150%;
  box-sizing: border-box;
  padding: 12px 16px;
  width: 100%;

  &:hover,
  &:active:hover,
  &:focus:hover {
    border-color: $border-color-blue;
  }

  &:active,
  &:focus {
    border-color: $border-color-info;
  }

  &::placeholder {
    color: $color-dark-grey-alt;
    font-weight: 400;
  }

  &--error {
    border-color: $border-color-error;
    transition: background-color 0.1s;

    &:active,
    &:focus {
      border-color: $border-color-info;
    }

    &:hover,
    &:active:hover,
    &:focus:hover {
      border-color: $border-color-blue;
    }
  }
}
