@import '../../assets/styles/variables.scss';

.Container {
  display: flex;
  width: 100vw;
  height: calc(100vh - $nav-height);
}
.AnnotationContainer {
  width: 50%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  color: #1a1919;
  padding: 2rem 4rem;
  box-sizing: border-box;

  .Predictions {
    width: 400px;
    margin-left: auto;
    margin-right: 48px;
    margin-bottom: 100px;
  }
}
.EmailContainer {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  background-color: #eff1f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
h1 {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 120%;
  color: #252f35;
}
.Buttons {
  position: fixed;
  z-index: 100;
  bottom: 5vh;
  margin-right: 5px;
}

.SubmitBtn {
  padding: 1rem;
}
.SkipBtn {
  background-color: #fff;
  color: #000;
  margin: 2.5rem 1rem 0 0;
  border: 1px solid #dcdfe2;
  padding: 1rem;
  &:hover {
    background-color: darken(#fff, 15%);
  }
}
