@import '../../assets/styles/variables.scss';

.Status {
  display: inline-flex;
  padding: 3px 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  border-radius: 2px;
  color: $label-orange;
  background-color: $label-light-orange;
  text-transform: uppercase;
  text-decoration: none;
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  height: 12px;
  align-items: center;
  box-sizing: content-box;

  &--green {
    color: $label-green;
    background-color: $label-light-green;
  }
  &--red {
    color: $label-red;
    background-color: $label-light-red;
  }
  &--blue {
    color: $label-blue;
    background-color: $label-light-blue;
  }
  &--yellow {
    color: $label-yellow;
    background-color: $label-light-yellow;
  }
  &--purple {
    color: $label-purple;
    background-color: $label-light-purple;
  }
  &--neutral {
    color: $label-neutral;
    background-color: $label-light-neutral;
  }
  &--orange {
    color: $label-orange;
    background-color: $label-light-orange;
  }
  &--transparent {
    color: $color-white;
    background-color: transparent;
    border: 1px solid $color-white;
  }

  &--top {
    margin-bottom: 8px;
  }
  &--bottom {
    margin-top: 8px;
  }

  &--dark {
    color: $color-white;
    background-color: $label-orange;

    &.Status--green {
      background-color: $label-green;
    }
    &.Status--red {
      background-color: $label-red;
    }
    &.Status--blue {
      background-color: $label-blue;
    }
    &.Status--yellow {
      color: $color-default;
      background-color: $label-yellow;
    }
    &.Status--purple {
      background-color: $label-purple;
    }
    &.Status--neutral {
      background-color: $label-neutral;
    }
    &.Status--orange {
      background-color: $label-orange;
    }
  }
}
