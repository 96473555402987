@import '../../assets/styles/variables.scss';

.Nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  font-size: 1rem;
  background: #068cf7;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
  z-index: 102;
  position: relative;
}
.Title {
  margin-left: 1.5rem;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
}
.Logo {
  position: absolute;
  left: 50%;
  margin-left: -24px;
  img {
    width: 32px;
  }
}
.Sidebar {
  margin-right: 1.5rem;
  position: relative;

  &:hover {
    .SidebarContent {
      display: block;
    }
  }
}
.Stats {
  display: flex;

  &.Stats:last-child {
    margin-right: 24px;
    margin-left: auto;
  }
}
.StatsContainer {
  padding: 12px 24px;
  border-bottom: 1px solid hsla(0, 0%, 63.1%, 0.3);

  h3 {
    font-size: 1rem;
    color: #1a1919;
    margin: 0;
    margin-bottom: 12px;
  }
}
.Stat {
  display: flex;
  color: #1a1919;
  line-height: 150%;
  letter-spacing: 0.03rem;

  .Info {
    text-align: center;
    span {
      display: block;
    }
    .InfoNumber {
      font-size: 1.5rem;
      font-weight: 900;
    }
    .InfoDay {
      font-size: 0.7rem;
      line-height: 125%;
      color: #7f9099;
      font-weight: 400;
      margin-top: 6px;
    }
  }
  .StatLogo {
    height: 24px;
    margin-right: 9px;
  }
  &.Last {
    margin-left: auto;
  }
}
.Action {
  padding: 0.7rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
.SidebarContent {
  font-weight: 500;
  display: none;
  min-width: 288px;
  position: absolute;
  right: 0;
  background: $color-white;
  color: $color-black;
  z-index: 200;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.4);
  margin-right: -1.5rem;
  cursor: default;
}

.SidebarHeader {
  display: flex;
  align-items: center;
  height: 56px;
  transition: background 0.4s;
  padding: 0 $gutter;
  font-size: 0.875rem;
  line-height: 17px;
  letter-spacing: 0.6px;
  color: #ffffff;
  cursor: default;

  img {
    margin: 0 0 4px 4px;
    width: 10px;
  }
}

.SidebarName {
  margin-left: 12px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;

  &__sub {
    font-size: 0.6875rem;
    line-height: 13px;
    color: #ffffff;
    opacity: 0.8;
  }
}
