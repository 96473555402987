@import '../../assets/styles/variables.scss';

.Avatar {
  width: 32px;
  height: 32px;
  text-transform: uppercase;
  border-radius: 32px;
  background: #fe6ba5;
  color: $color-white;
  position: relative;
}
.Initial {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
}
